export function defaultInstruments() {
  return [{ supportedMethods: ["basic-card"] }];
}

export function defaultDetails() {
  return {} as PaymentDetailsInit;
}

export function defaultOptions() {
  return {};
}

export function applyDefaultApplePaySettings(instruments: PaymentMethodData[]) {
  const applePayInstrument = instruments.find(
    (instrument) =>
      instrument.supportedMethods === "https://apple.com/apple-pay",
  );

  if (!applePayInstrument) {
    return;
  }

  if (!applePayInstrument.data) {
    applePayInstrument.data = {};
  }

  applePayInstrument.data.countryCode = applePayInstrument.data.countryCode || "US";
  applePayInstrument.data.merchantCapabilities = applePayInstrument.data.merchantCapabilities || ["supports3DS", "supportsCredit", "supportsDebit"];
  applePayInstrument.data.supportedNetworks = applePayInstrument.data.supportedNetworks || ["amex", "discover", "masterCard", "visa"];
  applePayInstrument.data.version = 3;
}
