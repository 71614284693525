import { IframeField } from "../../ui/iframe-field";

export default function complete(iframe: IframeField, status: PaymentComplete, paymentResponse: PaymentResponse) {
  if (!paymentResponse) {
    const error = {
       code: "ERROR",
       message: "Missing PaymentResponse object",
      };
    /* tslint:disable:no-string-literal */
    iframe.events["error"].forEach((cb) => {
      // @ts-ignore
      cb(error);
    });
    return;
  }

  paymentResponse
    .complete(status)
    .catch((e: Error) => {
      const error = {
        code: "ERROR",
        message: e.message,
      };
      /* tslint:disable:no-string-literal */
      iframe.events["error"].forEach((cb) => {
        // @ts-ignore
        cb(error);
      });
    });
}
