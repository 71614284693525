import { IError } from "../gateways";
import buildUrl from "../lib/build-tokenization-url";
import getGateway from "../lib/get-gateway";
import { IDictionary } from "../lib/util";

export default async (data: IDictionary, requestId: string) => {
  const gateway = getGateway();

  if (!gateway) {
    return Promise.reject({
      error: true,
      reasons: [
        { code: "INVALID_CONFIGURATION", message: "no gateway available" },
      ],
    });
  }

  if (!gateway.actions.tokenizeApplePay) {
    return Promise.reject({
      error: true,
      reasons: [
        {
          code: "INVALID_CONFIGURATION",
          message: "gateway does not support apple pay tokenization",
        },
      ],
    });
  }
  const resp = await gateway.actions.tokenizeApplePay(
    buildUrl(),
    data,
    requestId
  );
  const normalizedResp = gateway.actions.normalizeResponse(resp);

  if ((normalizedResp as IError).error) {
    return Promise.reject(normalizedResp);
  }

  return normalizedResp;
};
