import { loadedFrames, postMessage } from "../internal";
import { paymentRequestResponse } from "../internal/lib/apple-pay-payment-request-response";
import applePayComplete from "../ui/apple-pay/action-apple-pay-complete";

export default (status: PaymentComplete) => {
  const frames = loadedFrames;
  for (const frameId in frames) {
    if (!frames.hasOwnProperty(frameId)) {
      continue;
    }

    if (frames[frameId]?.frame.name !== "payment-request") {
      continue;
    }

    // @ts-ignore
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      const paymentResponse = (paymentRequestResponse as any).response as PaymentResponse;
      applePayComplete(frames[frameId]!, status, paymentResponse);
    } else {
      postMessage.post(
        {
          data: { status },
          id: frameId,
          type: "ui:iframe-field:payment-request-complete",
        },
        frameId,
      );
    }
  }
};
