import { paymentRequestResponse } from "../../internal/lib/apple-pay-payment-request-response";
import getRequestId from "../../internal/lib/get-request-id";
import tokenizeApplePay from "../../internal/requests/tokenize-apple-pay";
import validateMerchant from "../../internal/requests/validate-merchant";

export default async function startApplePaySession(
  details: PaymentDetailsInit,
  instruments: PaymentMethodData[],
  options?: PaymentOptions
) {
  const requestId = getRequestId();
  let response;
  try {
    const paymentRequest = new PaymentRequest(instruments, details, options);
    // @ts-ignore
    paymentRequest.onmerchantvalidation = (event) => {
      const applePayInstrument = instruments.find(
        (instrument) =>
          instrument.supportedMethods === "https://apple.com/apple-pay",
      );
      const merchantId = applePayInstrument?.data?.merchantIdentifier;
      const storeDisplayName = applePayInstrument?.data?.storeDisplayName;

      const applePaySessionPromise = validateMerchant(
        merchantId,
        requestId,
        storeDisplayName
      );
      event.complete(applePaySessionPromise);
    };

    response = await paymentRequest.show();
    (paymentRequestResponse as any).response = response;
  } catch (e) {
    let code = "ERROR";
    if (e.name !== "Error") {
      code = e.name.replace("Error", "_Error").toUpperCase();
    }
    return Promise.reject({
      error: true,
      reasons: [{ code, message: e.message }],
    });
  }

  try {
    const token = await tokenizeApplePay(response, requestId );

    (token as any).payment_request_response = {};

    // Cloning the details object to avoid mutation on the original response
    const clonedDetails = Object.assign({}, response.details);
    delete clonedDetails.token;
    if (!isEmpty(clonedDetails)) {
      (token as any).payment_request_response.details = clonedDetails;
    }

    (token as any).payment_request_response.methodName = response.methodName;
    (token as any).payment_request_response.payerEmail = response.payerEmail;
    (token as any).payment_request_response.payerName = response.payerName;
    (token as any).payment_request_response.payerPhone = response.payerPhone;
    (token as any).payment_request_response.requestId = response.requestId;
    (token as any).payment_request_response.shippingAddress =
      response.shippingAddress;
    (token as any).payment_request_response.shippingOption =
      response.shippingOption;

    return token;
  } catch (error) {
    response.complete("fail");
    return Promise.reject(error);
  }
}

/**
 * Checks if the object is empty
 * @param obj
 */
const isEmpty = (obj: any) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
};
